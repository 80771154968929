<template>
  <div class="container">
    <div class="page-title">账户审核</div>
    <div class="content">
      <div class="content-top">
        <div class="date-box">
          <div class="label" style="width: 150px;">请选择要查询的日期</div>
          <el-date-picker
            v-model="value2"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
          </el-date-picker>
        </div>
        <div class="date-box" style="padding-left: 12px;">
          <div class="label" style="width: 50px;">状态</div>
          <el-select v-model="value" placeholder="请选择" style=" width:150px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="date-box" style="padding-left: 12px;margin-right: 20px;">
          <div class="label" style="width: 65px;">关键字</div>
          <el-input
            style=" width:150px"
            placeholder="请输入内容"
            v-model="orderNumber"
            clearable>
          </el-input>
        </div>
        <el-button style="width: 100px;height: 32px;" size="small" type="primary">查询</el-button>
      </div>
      <div class="content-bottom">
        <el-table
          :data="tableData"
          stripe
          style="width:100%;flex:1;overflow:auto;"
          v-loading="listLoading"
          >
          <el-table-column
            prop="date"
            label="序号"
            width="100">
            <template slot-scope="scope">
              {{scope.$index + 1}}
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="150">
          </el-table-column>
          <el-table-column
            prop="qq"
            label="QQ号"
            width="150">
          </el-table-column>
          <el-table-column
            prop="wechat"
            label="微信号"
            width="150">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="手机"
            width="150">
          </el-table-column>
          <el-table-column
            label="时间"
            width="170">
            <template slot-scope="scope">
              {{scope.row.createTime | rTime}}
            </template>
          </el-table-column>
          <el-table-column
            label="审核"
            width="160">
            <template slot-scope="scope">
              <div style="color:#67c23a" v-if="scope.row.status === 2">
                <span style="margin-right: 5px;"> 已通过</span>
                <el-button :disabled="!accessConfig.manager"  @click="handleClick2(scope.row,2)" type="danger" size="small">停用</el-button>
              </div>
              <div style="color:#f56c6c"  v-else-if="scope.row.status === 3">已拒绝</div>
              <div v-else style="display: flex;align-items: center;">
                <el-button :disabled="!accessConfig.manager" @click="handleClick2(scope.row,1)" type="primary" size="small">通过</el-button>
                <el-button :disabled="!accessConfig.manager" @click="handleClick2(scope.row,2)" type="danger" size="small">拒绝</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 10, 20, 40]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      :total="total"
    ></el-pagination>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value2: '',
        orderNumber: '',
        tableData: [],
        options: [{
          value: '选项1',
          label: '已通过'
        }, {
          value: '选项2',
          label: '未通过'
        }, {
          value: '选项3',
          label: '未审核'
        }],
        value: '',
        total: 0,
        pageSize: 10,
        currentPage:1,
        listLoading: false,
        accessConfig: {}
      };
    },
    activated () {
      const functionCodeList = window.sessionStorage.functionCodeList;
      this.accessConfig = {
        manager: functionCodeList.includes('use_menu_account_manager'),
      }
      this.queryData(this.currentPage,this.pageSize);
    },
    methods: {
      queryData(currentPage,pageSize){
        this.$http.post(`/user/findUserByPage/${currentPage}/${pageSize}`, {  }, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            this.listLoading = false;
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.tableData = res.data.data.list;
              this.total = res.data.data.total;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.queryData(this.currentPage,this.pageSize);
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.queryData(this.currentPage,this.pageSize);
      },
      handleClick2(row,status) {
        console.log('row========',row);
        const url = status === 2 ? `/user/userRefuse/${row.id}` : `/user/userpass/${row.id}`
        this.$http.post(url, {
          id: row.id
        }, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.$message.success(res.data.data)
              this.queryData(this.currentPage,this.pageSize);
            } else {
              this.$message.error(res.data.data)
            }
          });
      }
    },
    filters: {
      rTime(data) {
        if(!data) return
        var dt = new Date(data);
        var y = dt.getFullYear();
        var m = (dt.getMonth()+1).toString().padStart(2,"0");
        var d = dt.getDate().toString().padStart(2,"0");
        var h = dt.getHours().toString().padStart(2,"0");
        var mm = dt.getMinutes().toString().padStart(2,"0");
        var s = dt.getSeconds().toString().padStart(2,"0");
        return `${y}-${m}-${d} ${h}:${mm}:${s}`;
      },
    }
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    margin-top: 10px;
    .content-top {
      display: flex;
      align-items: center;
      /*margin-top: 10px;*/
      margin-bottom: 10px;
      /*position: fixed;*/
      /*justify-content: flex-start;*/
      /*flex-direction: column;*/
      .first-line {
        display: flex;
        justify-content: flex-start;
      }
      .label {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        /*text-align: right;*/

        color: #000000;
        padding-right: 10px;
      }
      .date-box {
        display: flex;
        align-content: center;
        padding-left: 12px;
      }
    }
    .content-bottom {
      flex: 1;
      /*position: fixed;*/
      /*top: 185px;*/
      /*bottom: 0;*/
      /*left: 147px;*/
      /*right: 0;*/
      overflow: auto;
      /*margin-bottom: 10px;*/
      display: flex;
      flex-direction: column;
    }
    .content-bottom /deep/ .el-table__body {
      /*width: 100%!important;*/
    }
  }
</style>
